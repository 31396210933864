import CourseImage from "assets/writings/image1.jpg";
import CourseImage2 from "assets/writings/image2.jpg";
import CourseImage3 from "assets/writings/image3.jpg";
import CourseImage4 from "assets/writings/blogBanner.jpg";
import CourseImage5 from "assets/writings/blogBanner2.jpg";
import CourseImage6 from "assets/writings/blogBanner3.jpeg";
import blog from "assets/writings/blogContent.png";
import community from "assets/writings/community.jpg";
import childrenStudy from "assets/writings/childrenStudy.jpg";
import inclusive from "assets/writings/muk.jpg";
import alexMuk from "assets/writings/alexMuk.jpg";

export const posts = [
  {
    image_url: CourseImage6,
    author: "Tina Mbabazi",
    title: "Tips from my experience being a Coding Instructor at CodeImpact.",
    date: "28 . 10 . 2022",
    slug: "tips-from-my-experience-being-a-Coding-Instructor-at-CodeImpact.",
    desc: `
    <h2><em>The art of teaching is the art of assisting discovery.<br>&ndash; Mark Van Doren. Am very happy to be part of it<img class="emoji" role="img" draggable="false" src="https://s.w.org/images/core/emoji/14.0.0/svg/1f389.svg" alt="🎉" width="26" height="26" style="display: inline-block;">.</em></h2>
    <p>Mentoring and sharing knowledge are some of the things that I have a passion for doing aside from being a software developer. I feel very delighted when I am able to help in that regard.</p>
    <p>My work at&nbsp;<a href="https://codeimpact.co/">CodeImpact</a>&nbsp;is mostly aligned to teaching and mentoring teenagers in the enterprises of computer science and computer programming through CodeImpact&rsquo;s coding program, a program that brings together young people who are passionate about technology.</p>
    <p>The coding program aims to train not only teenagers but also pre-teens who have the passion to learn how to code. I strongly believe that exposing young children to technology and especially through computer programming, helps to instill great virtues and skills such as computational and creative thinking, problem-solving to mention but a few.&nbsp;<a href="https://codeimpact.co/">CodeImpact</a>&nbsp;also focuses on developing soft skills such as communication and collaboration through a pair programming mode of learning that is in place.</p>
    <p>I must admit that being around children and learning with them is very interesting because, unlike grownups, children are very inquisitive and so this helps them to learn a lot and quickly understand new concepts. As such that makes me really happy to be part of their learning experience.</p>
    <p>Mentoring at CodeImpact for the past year has been very interesting and of course at some point bit challenging but nevertheless, I am grateful for having learned from those challenges for they have allowed me to grow into a better learning instructor.</p>
    <p>Here are some tips that I would like to share with you. These tips are drawn from the lessons I have learned along the way;</p>
    <p class="has-text-align-center"><strong>. . .</strong></p>
    <h2 id="2e00">Tips and advice from my experience</h2>
    <p><strong>&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;</strong></p>
    <h3><strong>Enrich the Coding Curriculum</strong>:</h3>
    <p>Your learning curriculum is very important and you should ensure that it&rsquo;s flexible and easy to adjust based on the results you get when you have tested it with your learners. The Curriculum should encourage a more hands-on approach to learning and favor more learner engagement. This will help you achieve a more engaging and fun coding program.</p>
    <h3><strong>Embody the stance of a Learner:</strong></h3>
    <p>As a coding instructor, it&rsquo;s important not to deliver sessions in &ldquo;lecture mode&rdquo;, otherwise your students will get bored. It would be nice if you encourage classroom engagement by presenting yourself to students as a learner rather than being an&nbsp;<em>&ldquo;I know it all&rdquo;</em>&nbsp;person. Put your learners in the driver&rsquo;s seat because it encourages learners to freely share their ideas and thoughts with you which is really amazing. This makes the students also feel special because that way they feel that their opinions matter to you.</p>
    <p><a href="https://www.iste.org/explore/In-the-classroom/5-ways-to-gamify-your-classroom"><strong>Gamify the learning experience:</strong></a></p>
    <p>Incorporate unplugged activities before the start of your class such as&nbsp;<em>&ldquo;Simons says&rdquo;</em>,&nbsp;<em>&ldquo;heads up&rdquo;</em>&nbsp;and perhaps some short quizzes. Unplugged activities help in teaching key concepts such as sequencing, algorithms, variables without the need of a computer. These activities make learning interesting and memorable for learners given the fact that all senses are involved.</p>
    <p><a href="https://www.youtube.com/watch?v=vgkahOzFH2Q"><strong>Pair Programming:</strong></a></p>
    <p>I highly encourage pair programming other than assigning learners individual projects. With pair programming, students are encouraged to communicate, collaborate, learn to give each other constructive feedback and they eventually learn to value teamwork which is very important. When it comes to the projects, I encourage that you don&rsquo;t dictate what they have to build/create but rather, allow them to be creative to develop projects based on their imagination. You will be amazed by their work.</p>
    <p>These are some of the lessons I have learned from my experience being a coding instructor and they have enabled me to achieve high classroom engagement and participation too.<br>If you wish to train or mentor future technology leaders, am certain that these tips will help you a lot too.</p>
    <p>Otherwise, I am really glad to be part of the force that&rsquo;s mentoring the next technology leaders and I look forward to mentoring more, training, and creating content that can enable future developers to have a great learning experience as they evolve into their next best selves as technology leaders.</p>
    <p class="has-text-align-center"><strong>. . .</strong></p>
    <p>Thanks for reading until the end. I hope you enjoyed this article.<br>Feel free to connect with me on&nbsp;<a href="https://www.linkedin.com/in/roland-sankara-808162192/">LinkedIn</a>, I will be more than happy to hear from you.</p>
<p>Special thanks to&nbsp;<a href="https://ug.linkedin.com/in/alex-shyaka">Alex Shyaka</a>&nbsp;my mentor and the entire&nbsp;<a href="https://codeimpact.co/about/">CodeImpact</a>&nbsp;team.<br>I am deeply indebted to them for all their support.</p>
    `,
  },
  {
    image_url: CourseImage5,
    author: "Tina Mbabazi",
    title: "Community means a lot to us at CodeImpact",
    date: "28 . 10 . 2022",
    slug: "community-means-a-lot-to-us-at-CodeImpact",
    desc: `
    <img src=${community} width="400px" height="auto" style="margin-left: auto; margin-right: auto;" />
    <p>CodeImpact is invested in making sure every child and young adult has a chance of exposure to tech mentorship.</p>
    <p>Our work goes beyond just opening up tech mentorship clubs in schools that teach coding and robotics, but we think of it holistically.</p>
    <p>Beyond the technical skills which undoubtedly are important, we engage the learners in career guidance and other soft skills that are necessary to compete in the global workforce.</p>
    <p>Skills like integration in diverse teams, clear communication both written and spoken, giving and receiving ASK(Actionable, Specific and Kind) feedback, and many others.&nbsp;</p>
    <p>In contemporary times, where remote work has become increasingly normalized, and teams are becoming more diverse, skills in teamwork and integration and good communication are very important.</p>
    <p><strong>How is CodeImpact playing its part?</strong></p>
    <p>We are building a strong and collaborative community of developers that build, work collaborate and network together to build long-lasting relationships that they can count on in the future.</p>
    <p>Through these connections, they recommend each other for jobs, start businesses together, work on open source projects together which inevitably help enhance their individual portfolios.</p>
    <p><strong>How do you become part of the community?</strong></p>
    <p>By subscribing to our tech mentorship clubs in schools you automatically become a member of the online community. Our weekend programs where the learners get extra time with our learning facilitators are important since one hour a week in the schools is not enough to learn a new skill. We call it the&nbsp;<strong><em>continuity tech mentorship</em></strong> program for club subscribers.</p>
    <figure><img src=${childrenStudy} width="80%" height="auto" style="margin-left: auto; margin-right: auto;" /><figcaption style="font-size: 15px; text-align: center">Kids at Atlas Junior listening to one of CodeImpact’s facilitators. It was an introduction to tech mentorship clubs</figcaption></figure>
    <p>Through our&nbsp;<strong><em>private tutoring initiative</em></strong>, we organize 8 to 12-week programs that are all project-based, because we believe that is the best way to learn, and all learners that are part of this are also legible to join the community after they finish their various programs. This initiative is both remote and face to face. Here is one of the projects that our private tutoring students built.</p>
    <figure><img src=${CourseImage5} width="80%" height="auto" style="margin-left: auto; margin-right: auto;" /><figcaption style="font-size: 15px; text-align: center">Kids at Atlas Junior listening to one of CodeImpact's facilitators. It was an introduction to tech mentorship clubs.</figcaption></figure>
    <p>As our work gets noticed and the impact felt, we get contacted by parents who would like their children to learn and tinker but we are do not have a presence in their schools. We launched our Neighborhood tech mentorship spaces(NTMSes), and our first one is located in Mulawa in the Bulindo area at a place called 5D ranch. We are working hard to open even more of these so that we can reach as many interested learners as possible.</p>
    <figure><img src=${inclusive} width="80%" height="auto" style="margin-left: auto; margin-right: auto;" /><figcaption style="font-size: 15px; text-align: center;">An inclusive session at Kyambogo University</figcaption></figure>
    <p><strong>Inclusiveness</strong></p>
    <p>Something has become close to my heart of late, and that is inclusiveness in tech and to be specific inclusiveness for the disabled and for women. I have recently been involved in the<a href="https://tunaweza.org/">&nbsp;Tunaweza</a>&nbsp;efforts to evangelize inclusiveness in education and I&rsquo;m now determined more than ever to make sure all our efforts at CodeImpact are cognizant of this fact.</p>
    <figure><img src=${alexMuk} width="80%" height="auto" style="margin-left: auto; margin-right: auto;" /><figcaption style="font-size: 15px; text-align: center">Alex Shyaka CEO CODEIMPACT talking about inclusiveness in Tech at Makerere University</figcaption></figure>
    <div class="wp-block-image">The above are our core initiatives and through this, we aim to train over one hundred thousand software engineers, product and project managers, community developers, and many more career paths that one can take in the tech space. We believe there is room for everyone!</div>
    </div>
    `,
  },
  {
    image_url: CourseImage4,
    author: "Tina Mbabazi",
    title: "Our Learning Journey At CodeImpact",
    date: "28 . 10 . 2022",
    slug: "our-learning-journey-at-CodeImpact",
    desc: `
    <p>Our learning journey is core to our teens&rsquo; coding program and we endeavor to make sure that we constantly tweak and improve it so that we can have a seamless program.&nbsp;</p>
    <p>We kick off with a free two-week program and this is designed to introduce the learners to the concept of critical thinking and problem solving using computer science concepts. We use<a href="https://scratch.mit.edu/">&nbsp;visual programming tools</a>&nbsp;that are suited for the 8+ years to 12 years age bracket.</p>
    <p>After the two-week program, we kick off with an 8-week program that focuses on front-end web development technologies like HTML(HyperText Markup Language) and CSS(Cascading style sheets).</p>
    <p>HTML is one of the major fundamental languages of the web because at the end of the day everything gets converted into HTML before it is displayed in our browsers. I will spare you the technical details.</p>
    <p>CSS gives web pages the look and feel and is focussed on aesthetics and enhances a good user interface and user experience design.</p>
    <p>Our last phase focuses on Javascript, the de-facto language of the web. JavaScript is also known as JS adds interactivity to a web application and gives it life. Mastering the language will enable our learners to build web applications that can store, retrieve, delete, and update information in databases. They will be able to build tools that people can actually use.</p>
    <p>At this level, the learners get to work on projects, work in groups and present their work to their peers and to the parents on graduation day. Through the 8 weeks, we also have skill sessions on soft skills and technical skills. This is so we can mold a wholesome, well-balanced developer that can work independently on diverse teams in different geographical locations.</p>
    <p>Our job is to mentor technology leaders for tomorrow, and the onus is on us as tech entrepreneurs, parents, and well-wishers to get onboard and be strong stakeholders in our passion and desire to be technology players and not just consumers.&nbsp;</p>
    <p>All graduates of our program that have demonstrated the passion to learn and grow have an opportunity to join&nbsp;<a href="https://codeimpact.co/">CodeImpact&nbsp;</a>as interns where they will work on real-world client projects.</p>
    <p><a href="https://codeimpact.co/">CodeImpact</a>&nbsp;has started the long journey, and with all hands on deck, we will provide the opportunity where it lacks, because we are well aware that talent is evenly distributed but opportunity is not!</p>
    <img src=${blog} width="400px" height="auto" style="margin-left: auto; margin-right: auto;" />
    <p><a href="https://github.com/Roland-Sankara/CodeImpact-Curriculum-Guide/wiki">Our curriculum</a>&nbsp;is designed to be interactive and project-based. Our facilitators record videos and share them with the learners before the class, so the learners can watch and come to the class ready to ask questions and engage the facilitator. This approach makes the class a lot more interactive and fun and this increases the stickiness of the knowledge.</p>
    <p>I have always grappled with;&nbsp; then what, when they finish the coding program? So, we started the developer community. This community is free for all to join and it is the place where a lot of growth will happen.&nbsp;</p>
    <p>Through the community, we mentor and guide the learners to work on projects together, start companies together, and recommend each other for jobs. It is where they will grow their network of technology enthusiasts and practitioners that they will always fall back on when they are ready for the job market or for the business of tech startups.</p>`,
  },
  {
    image_url: CourseImage,
    author: "Vipul Kumar",
    title: "Top 10 Programming Languages for Kids in 2022",
    date: "28 . 09 . 2022",
    slug: "top-10-programming-languages-for-kids-in-2022",
    desc: `
    <p>Programming is a great skill for kids to learn and develop problem-solving skills. Starting early with programming will make sure that the children are already ahead of their peers in the future who missed the advantage of learning to code at an early age.</p>
    <p>The basics of programming languages are something that anyone who will one day work with computers should have a handle on.
    There are numerous programming languages for kids to choose from. However, we have listed some of the best applications that teach kids coding effectively and in an interactive way.</p>
    <p>Here is an overview of the top 10 programming languages for kids to start with for kids who are interested in learning to code.
    The tops 10 programming languages for kids are Python, Scratch, Java, Ruby, C++, Swift, JavaScript, Lua, HTML, Blockly</p>
    <p><b>1. Python</b></p>
    <p>Python is one of the programming languages that read like everyday speech. It’s the most versatile programming language to create numeric and scientific computing projects. Many standard functionalities that programmers need are already built-in, making this an excellent language for kids to learn.
    With Python, students will have very few issues about learning how to program or code. Instead, they will be able to learn how to construct programming ideas within their heads and then focus on transferring the ideas into instructions that the machine can interpret.
    </p>
    <p><b>2. Scratch</b>
    <span>
    Scratch is an interactive, drag-and-drop programming language that allows users to make animations and games. It’s perfect for beginners, as it helps them understand the programming logic without prior knowledge. The learning gained in <a className="dark:text-orange-500" href="https://cdg.al/Uk3CH" target="_blank">Scratch programming</a> can then be applied to real programming languages.
    It’s a block-based programming language that works on a visual interface. The best part about Scratch is that it has video tutorials that help kids learn to program.
    </span></p>
    <p><b>3. Java</b>
    <span>
    Java is one of the most popular programming languages for kids. It is widely used for developing applications on Android platforms. In addition, Java is easy to learn, whether kids are brand new at coding or interested in learning an additional language.
    Applications built with Java are more robust as they are measurable and scalable. However, Java takes longer to master compared to other programming languages.
    For kids who love Minecraft, Java is likely the best language to start with, as Java is the language of Minecraft. In addition, its English-like syntax makes Java easy to understand and quick to learn.
    </span></p>
    <p><b>4. Ruby</b>
    <span>
    Ruby is one of the most readable syntaxes for programmers. Instead of spending a lot of time explaining the code that students are typing into machines, a lot of it will be self-explanatory for them. 
    Ruby is a dynamic, open-source programming language focused on simplicity and productivity. It’s super easy to learn and makes coding as uncomplicated as possible. 
    Ruby is most used for building web applications. It is one of the best programming languages that can help children to learn the concepts in order to write good scripts.
    </span></p>
    <p><b>5. C++</b>
    <span>
    Many computer science experts and developers have perspectives about kids learning C++ or not. This is one of the programming languages that can look a bit like math and might put students off the idea of learning to code. But once the student understands the basics, it becomes one of the easiest languages for them.
    </span></p>
    <p><b>6. Swift</b>
    <span>
    Swift is a convenient language to write software, whether it’s for phones, desktops, servers, or anything else that runs code. It’s a safe, fast, and interactive programming language. 
    Learning Swift has been a great introduction to modern programming concepts and best practices for students.
    Swift programming skills can be applied to a broader range of platforms, from mobile devices to the desktop to the cloud. The best part about Swift is that it works on a simple drag-and-drop functionality, which is super convenient for development.
    </span></p>
    <p><b>7. Javascript</b>
    <span>
    JavaScript is one of the top programming languages for kids that is used widely. It is simple to start learning and does not require any heavy setup. It is called the internet language, as all the games and interactive websites are created using JavaScript.
    Kids can learn JavaScript easily as it has very intuitive syntax and is very flexible when running the scripts on any platform.
    </span></p>
    <p><b>8. Lua</b>
    <span>
    Lua stands out from the crowd, but it is still relatively unknown. This language has been used for well over two decades. 
    Lua has mainly been used in video games and game engines, but it’s also used as a programming language in many network and system programs.
    However, it’s rarely used as a standalone programming language. Instead, developers use it as an embedded scripting language for individual programs.
    </span></p>
    <p><b>9. HTML</b>
    <span>
    HTML is used to create web pages and web applications. And CSS alters and enhances the appearance of a web page or web application.  All web pages are internally made up of HTML content. 
    CSS defines the styles for all the elements present on the web pages created using HTML.Kids can learn HTML very quickly as it is simple to learn and understand.
    </span></p>
    <p><b>10. Blocky</b>
    <span>Blockly is developed by the Google developer community. It is designed to provide its users to create and indulge in programming through block-based visual programming. 
    Kids can quickly understand and learn the design of a program with this. Blockly is used with other languages like JavaScript, Lua, and Python to generate the code in the respective languages through visual block linking. 
    </span></p>
    <p><b>Conclusion</b>
    <span>
    Coding for kids can help kids become innovators, problem solvers, and future creative thinkers. Codingal motivates kids to bring their imagination to life by engaging in coding course activities individually and in groups.
    Try a free class and get started on the perfect pathway for kids to explore and learn coding with Scratch.
    Let kids explore their interests and grow; as a result, becoming better prepared for the future and all it holds!
    </span></p>
    `,
  },
  {
    image_url: CourseImage3,
    author: "Vipul Kumar",
    title: "5 Scratch Projects Ideas for Kids",
    date: "28 . 09 . 2022",
    slug: "5-scratch-projects-ideas-for-kids",
    desc: `
    <p>Scratch for kids is an excellent block-based coding platform for building 21st-century skills, such as systematic thinking, creativity, and collaboration.
It’s the perfect pathway for kids to learn programmatic thinking and get an enthusiastic head start on real-world coding skills.
In Scratch, a program is saved as a project. So kids can get started with creating a Scratch project in three simple and easy steps.
First, you need to sign up on the Scratch website. Next, explore the “ideas” section to access the getting started tutorial. Later, create your first project using Scratch.
    </p>
    <p>In this blog, we will explore some exciting Scratch projects for kids. So read on to find some innovative ideas and try these starter projects.</p>
    <p><b>1. DJ Scratch Cat</b>
    <span>
    DJ Scratch Cat is a music-based Scratch project that lets you mix sounds and graphics. As per the instructions on the Scratch website, you press V, B, N, or Z, X, C to play with sounds.
You can explore new tunes and keys or place another DJ inside the setup instead of Scratch Cat.
    </span><a href="https://scratch.mit.edu/projects/11640429/" class="text-red-500" target="_blank" rel="noreferrer">Create your own version of DJ Scratch Cat</a>
    </p>
    <p><b>2. Maze Starter</b>
    <span>
    Maze Starter is a fun Scratch project where you move a ball to reach the goal using the arrow keys on your keyboard. You can customize the game by adding more obstacles and constructing new levels.
    It also includes score-keeping functionality: you collect rewards for choosing the correct path, subtract points for hitting walls, and so on.
    </span><a href="https://scratch.mit.edu/projects/10128431/embed" class="text-red-500" target="_blank" rel="noreferrer">Create your own version of Maze Starter project</a>
    </p>
    <p><b>3. Starfish Choir</b>
    <span>
    The Starfish Choir is a music-themed, fun Scratch project. 
    When you continuously click on the Starfish, it sounds like a choir. You can remix this project with different characters, backdrops, and sounds.
    You can add several Starfish to your project and assign a different sound to each.
    </span><a href="" target="_blank" class="text-red-500" rel="noreferrer">Create your own version of Starfish Choir project</a>
    </p>
    <p><b>4. Dance Party</b>
    <span>
    The dance party Scratch project lets you curate your dance party. Pick from different options, add sprites (images created in Scratch), and have fun with the interface. You can also view others’ creations, learn from the samples, and remix your  version.
    <a href="https://scratch.mit.edu/projects/10128431/embed" class="text-red-500" target="_blank" rel="noreferrer">Create your own version of the Dance Party project</a>
    </span></p>
    <p><b>5. Hide and Seek</b>
    <span>
    Hide and Seek is a Scratch project centered around a character named Gobo. Players will have to catch all the characters to win the game. You will have to click on Gobo to score points. You can add a timer or change what happens upon catching Gobo. 
    To increase the difficulty level further, you can shorten the length of time for which your characters appear on the screen. Another way to remix the project is to make more sprites similar to Gobo.
    </span><a href="https://scratch.mit.edu/projects/10128431/embed" class="text-red-500" target="_blank" rel="noreferrer">Create your own version of Hide and Seek project</a>
    </p>
    <p><b>Conclusion</b>
    <span>
    Developing projects is a fun activity for kids, as it is fun and helps them learn. Children who create with Scratch online learn to think creatively, work collaboratively, and reason systematically. Scratch for kids promotes computational thinking, problem-solving skills, self-expression, and collaboration.
    Check out our <a href="https://cdg.al/Uk3CH" class="text-red-500" target="_blank" rel="noreferrer">Scratch programming</a> course to start building your fun Scratch projects. 
    It will help you master the core coding principles using Scratch. Then, you can start using events, coordinates, and sprites and create your programs using code blocks. <a href="https://cdg.al/Fi6kw" target="_bank" rel="noreferrer">Coding for kids</a> helps kids foster essential 21-st century skills while improving academic performance. You can go ahead and <a href="https://cdg.al/EiZTK" target="_blank" rel="noreferrer">try a free class</a> today!
    </span></p>
    `,
  },
  {
    image_url: CourseImage2,
    author: "Vipul Kumar",
    title:
      "What is Scratch Programming? 7 Benefits of Learning Scratch Programming for Kids",
    date: "28 . 09 . 2022",
    slug: "what-is-scratch-programming-7-benefits-of-learning-scratch-programming-for-kids",
    desc: `
    <p><b>What is Scratch programming?</b>
    <span>
    Scratch is a visual programming language created by the MIT Media Lab in 2007. Most traditional programming languages tend to be complicated and text-based. However, Scratch works as a free-ware block-based programming tool for children. 
    </span>
    <span>Scratch empowers young kids to build interactive games and programs by connecting code blocks easily. The best thing about it is the simplicity that it adds to learning by removing all the complicated elements of programming.</span>
    </p>
    <p><b>Let’s explore the 7 amazing benefits of learning Scratch programming for kids.</b></p>
    <p><b>1. Scratch is fun and creative</b>
    <span>One of the top benefits for kids to code in Scratch is that it’s a lot of fun.
    Scratch for kids allows children to think outside the box and fully express themselves. 
    Scratch makes learning to code a challenging puzzle instead of a chore by removing the burden of learning many coding terms and focusing on the concepts. That makes learning enjoyable and a lot simpler.
    </span></p>
    <p><b>2. Encourages creative thinking</b>
    Scratch’s slogan is Imagine—Program—Share! It’s designed primarily for kids to encourage creativity by providing them with an easy-to-learn yet powerful programming platform.
With Scratch, kids can create interactive stories with any character they can imagine. It enables kids to unleash the power of their imagination.
Scratch facilitates the development of projects using a mixture of media, graphics, and sound to create something new. 
    </p>
    <p><b>3. Helps Problem Solving</b>
    Every step in learning to code in Scratch problem solving in some way. 
Scratch programming helps kids develop programmatic thinking. This involves kids breaking down problems into smaller sub-components by organizing code blocks and exploring multiple solutions to problems.
</p>
    <p><b>4. Easy to Grasp</b>
    One of the biggest challenges of coding is that it requires a lot of resources to grasp and understand fully. However, <a href="https://cdg.al/Uk3CH" class="text-red-500" target="_blank" rel="noreferrer">Scratch programming</a>  is easy to understand. Children do not need complicated books, guides, and tutorials to understand how the language works.
    </p>
    <p><b>5. Highly Accessible</b>
    <span>
    Almost anyone can use Scratch because all it takes is an internet connection. It doesn’t require any specialized programs to run, just a web browser and an account.
Therefore, Scratch programming is highly accessible, and kids can learn to code from the comfort of their homes.
    </span></p>
    <p><b>6. Develops Logical Thinking</b>
    With Scratch, kids can drag and drop code blocks. They can see what they are building and the result of their actions right away. This helps kids to understand and analyze the logic behind the programs.
Logic with Scratch builds on foundational concepts such as variables, data types, lists, arrays, loops, and operators. These skills also translate to other languages when students advance from popular block-based coding to text-based coding.
</p>
    <p><b>7. Serves as an introduction to <a href="https://cdg.al/Fi6kw" class="text-red-500" target="_blank" rel="noreferrer">coding for kids</a></b>
    Scratch coding helps kids get a grip on the basics that support all kinds of coding languages. By engaging with Scratch, kids get a taste of what programming is like. 
They can develop their skills and branch out into various niches per their interests. 
Scratch enables kids to focus their energy on the fun stuff, thinking of ideas for new programs, working out how to build them, designing them, and sharing them with friends.
Therefore, in every way, Scratch for kids is an excellent launchpad for young minds.
    </p>
    <p><b>Conclusion</b>
    <span>Scratch makes learning easy through experimentation. Of course, it’s always easier to learn if you have someone to guide you through learning. 
For structured Scratch learning, Codingal offers a project-based <a href="https://cdg.al/Uk3CH" class="text-red-500" target="_blank" rel="noreferrer">Scratch programming</a>  course for kids ages 8-14. 
</span></p>
<p><span>Our Scratch classes are a fun and exciting way to build a foundation of skills that prepares kids for the future. 
Our Scratch curriculum prepares students with complete mastery of the Scratch environment and prepares them to advance into high-level programming.
Codingal motivates kids to bring their imagination to life by engaging in Scratch course activities individually and in groups.
</span></p>
<p><a href="https://cdg.al/EiZTK" class="text-red-500" target="_blank" rel="noreferrer">Try a free class</a> and get started on the perfect pathway for kids to explore and learn coding with Scratch.
Let kids explore their interests and grow; as a result, becoming better prepared for the future and all it holds!
    </p>
    `,
  },
];
