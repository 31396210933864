import Card from "../../../assets/card.png";

export const Posts = () => {
  const post = [
    {
      image_url: Card,
      title: "Coding and Robotic clubs",
      button: {
        title: "Register school",
        url: "https://forms.gle/g3dtcAbagD8DsLCq8",
      },
      content:
        "Our coding and robotic clubs are tech membership spaces of excellence we setup in schools. The are weekly after schools tech clubs that we run during the school calendar year",
    },
    {
      image_url: Card,
      title: "One-on-One tech mentorship",
      button: {
        title: "Register",
        url: "https://forms.gle/HP25Zqp6MBy51nQK9",
      },
      content:
        "Our One-on-One tech mentorship programs are designed for those that prefer to learn at their own pace and at their convenience.",
    },
    {
      image_url: Card,
      title: "Industry level preparation",
      button: {
        title: "Register",
        url: "https://forms.gle/jEtpubG7uAqznE536",
      },
      content:
        "Our answer to unemployment among the youth by delivering market ready tech skills and connecting the youth to jobs in the local and remote tech industry.",
    },
  ];

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 mx-auto">
        {post.map((post, index) => (
          <div
            key={index}
            className="bg-white dark:bg-slate-500 dark:text-white shadow-xl rounded-lg opacity-90"
          >
            <div className="h-64 w-full rounded-t-lg bg-black">
              <img
                className=" object-cover h-full w-full rounded-t-lg"
                src={post.image_url}
                alt="..."
                loading="lazy"
              />
            </div>
            <div className="px-4 py-4 space-y-4">
              <p className="text-xl font-bold ">{post.title}</p>
              <p className="text-md text-left">{post.content}</p>
              <div>
                <a
                  href={post.button.url}
                  className="py-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="w-full bg-slate-900 text-white px-4 py-2 rounded">
                    {post.button.title}
                  </button>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
