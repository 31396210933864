import robot from "assets/robot.png";

export const Project = () => {
  return (
    <div className="space-y-14 lg:space-y-32">
      <div className="dark:text-white space-y-4 flex flex-col md:flex-row rounded-lg md:space-x-8">
        <div className="md:w-1/3">
          <img src={robot} alt="target" className="mx-auto h-full" />
        </div>
        <div className="md:w-2/3 space-y-8">
          <p className="font-bold text-xl">Codingal school</p>
          <p className="">
            We as codeimpact have a partnership with artec robots. Through our
            different school outreaches and mentorships. We use Artec
            educational kits to teach and mentor tech children on how to
            assemble, operate, think, and program these robots. We are very
            appreciative with Artec for developing and producing this kit.
          </p>
          <a
            href="https://www.codingal.com/en-UG/"
            className="py-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="my-4 py-2 px-4 rounded bg-slate-900 text-white">
              Learn more
            </button>
          </a>
        </div>
      </div>
      <div className="dark:text-white space-y-4 flex flex-col md:flex-row rounded-lg md:space-x-8">
        <div className="order-2 md:order-1 md:w-2/3 space-y-8">
          <p className="font-bold text-xl">Arctec Educational Kit/Robot</p>
          <p className="">
            We as codeimpact have a partnership with artec robots. Through our
            different school outreaches and mentorships. We use Artec
            educational kits to teach and mentor tech children on how to
            assemble, operate, think, and program these robots. We are very
            appreciative with Artec for developing and producing this kit.
          </p>
          <a
            href="https://www.artec-kk.co.jp/en/"
            className="py-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="my-4 py-2 px-4 rounded bg-slate-900 text-white">
              Learn more
            </button>
          </a>
        </div>
        <div className="md:w-1/3 order-1 md:order-2">
          <img src={robot} alt="target" className="mx-auto h-full" />
        </div>
      </div>
    </div>
  );
};
