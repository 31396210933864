export const PageHeader = ({ children, image, title }) => {
  return (
    <>
      <div className="pt-12 space-y-12 2xl:w-[1280px] 2xl:mx-auto h-5/6 items-center 2xl:h-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 justify-between items-center h-full">
          <div className="text-4xl md:text-5xl font-extrabold">{children}</div>
          <div className="">
            <img
              src={image}
              alt="..."
              className="h-auto mx-auto"
              loading="lazy"
            />
          </div>
        </div>
        {title ? (
          <div className="space-x-4 flex ">
            <div className="bg-black w-0.5 h-6"></div>
            <p className="font-black text-xl">{title}</p>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
