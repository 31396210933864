import { MainLayout } from "component/layout/MainLayout";
import { Blog } from "./components/Blog";
import { useEffect } from "react";
import { Experience } from "./components/Experience";
import { Project } from "./components/Project";
import { Posts } from "./components/Posts";
import { Partnership } from "./components/Partnership";
import Header from "assets/header.svg";
import { PageHeader } from "component/layout/components/PageHeader";

export function Landing() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <MainLayout page="home">
      <div className="page-header">
        <PageHeader image={Header}>
          <div>
            <p className="font-bold dark:text-black leading-[4rem]">
              Join our <span className="text-red-500">P7 </span>Vacists
              <span className="text-red-500"> Tech Mentorship</span> Program
              this <br />
              <span className="text-red-500"> 21st </span>November 2022
            </p>
            <div className="my-8">
              <a
                href="https://forms.gle/17C8hhXJ1nbhxcTM6"
                target="_blank"
                rel="noreferrer"
              >
                <button className="font-bold bg-slate-800 text-white py-3 px-5 rounded text-xl">
                  Join here
                </button>
              </a>
            </div>
          </div>
        </PageHeader>
      </div>
      <div className="bg-components">
        <div className="px-8 lg:px-32 2xl:w-[1280px] 2xl:mx-auto pt-14 space-y-14 md:space-y-24">
          <Project />
          <Posts />
          <Partnership />
          <p className="font-bold text-2xl pb-14 dark:text-white">
            Our Experience
          </p>
        </div>
      </div>
      <div className="bg-gray-900 py-14 px-14">
        <div className=" 2xl:w-[1280px] 2xl:mx-auto">
          <Experience />
        </div>
      </div>
      {/* <!--Blog section--> */}
      <div className="bg-components">
        <div className="px-8 lg:px-32 py-14 2xl:w-[1280px] 2xl:mx-auto">
          <Blog />
        </div>
      </div>
    </MainLayout>
  );
}
